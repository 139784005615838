import consumer from "./consumer";

consumer.subscriptions.create(
  { channel: "EventChannel", room: "events" },
  {
    connected() {
      // Called when the subscription is ready for use on the server
      // console.log("Connected to the EventChannel");
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
      // console.log("Disconnected from EventChannel");
    },

    received(data) {
      // console.log("Received from EventChannel", data);
      // reloadWithTurbolinks();
    },
  }
);

var reloadWithTurbolinks = (function() {
  var scrollPosition;
  var focusId;

  function reload() {
    Turbolinks.visit(window.location.href.toString(), { action: "replace" });
  }

  document.addEventListener("turbolinks:before-render", function() {
    scrollPosition = [window.scrollX, window.scrollY];
    focusId = document.activeElement.id;
  });
  document.addEventListener("turbolinks:load", function() {
    if (scrollPosition) {
      window.scrollTo.apply(window, scrollPosition);
      scrollPosition = null;
    }
    if (focusId) {
      document.getElementById(focusId).focus();
      focusId = null;
    }
  });
  return reload;
})();
